// Custom map scripts


jQuery(function($) {
    // Asynchronously Load the map API 
    var script = document.createElement('script');
    script.src = "//maps.googleapis.com/maps/api/js?key=AIzaSyAIJ9f4QBbwtU-sOMz6PC15VL5f13zwtaY&sensor=false&callback=initialize";
    document.body.appendChild(script);
});

function initialize() {

    // The circles for the map
    var citymap = {
        yorkCentre: {
          center: {lat: 53.9585914, lng: -1.1156109},
          size: 602714
        },
        // westYorkshire: {
        //   center: {lat: 53.7411188, lng: -1.966225},
        //   size: 32714
        // },
        // northYorkshire: {
        //   center: {lat: 54.0893066, lng: -1.9489921},
        //   size: 38408
        // },
        // yorkRyedale: {
        //   center: {lat: 54.1981063, lng: -1.1221848},
        //   size: 33877
        // },
        // eastYorkshire: {
        //   center: {lat: 53.8740876, lng: -1.0382732},
        //   size: 36350
        // }
  };


  var map;
  var bounds = new google.maps.LatLngBounds();
  var mapOptions = {
    mapTypeId: 'roadmap',

    // styles

    styles: [
    {
        "featureType": "all",
        "stylers": [
        {
            "saturation": 0
        },
        {
            "hue": "#e7ecf0"
        }
        ]
    },
    {
        "featureType": "road",
        "stylers": [
        {
            "saturation": -70
        }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    },
    {
        "featureType": "poi",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    },
    {
        "featureType": "water",
        "stylers": [
        {
            "visibility": "simplified"
        },
        {
            "saturation": -60
        }
        ]
    }
    ]
};

if (document.getElementById('map_canvas') !== null) {   
    // Display a map on the page
    map = new google.maps.Map(document.getElementById("map_canvas"), mapOptions);
    map.setTilt(45);

    // Multiple Markers
    var markers = [
    ['AJ Recruitment', 53.7999591,-1.4289659]
    ];

    // Info Window Content
    var infoWindowContent = [
    ['<div class="info_content">' +
    '<h3>AJ Recruitment</h3>' +
    '<p>AJ Social Care Recruitment Ltd<br>4225 Park Approach<br>Leeds, West Yorkshire<br>LS15 8GB</p>' +        
    '</div>']
    ];

    // Display multiple markers on a map
    var infoWindow = new google.maps.InfoWindow(), marker, i;
    
    // Set the marker image
    var image = templateUrl + '/assets/images/dropper.png';

    // Loop through our array of markers & place each one on the map  
    for( i = 0; i < markers.length; i++ ) {
        var position = new google.maps.LatLng(markers[i][1], markers[i][2]);
        bounds.extend(position);
        marker = new google.maps.Marker({
            position: position,
            map: map,
            title: markers[i][0],
            icon: image
        });
        
        // Allow each marker to have an info window    
        google.maps.event.addListener(marker, 'click', (function(marker, i) {
            return function() {
                infoWindow.setContent(infoWindowContent[i][0]);
                infoWindow.open(map, marker);
            };
        })(marker, i));

        // Automatically center the map fitting all markers on the screen
        map.fitBounds(bounds);
    }

       // Construct the circle for each value in citymap.
        // Note: We scale the area of the circle based on the size.
        // console.log('here');
        for (var city in citymap) {
            // console.log(city);
          // Add the circle for this city to the map.
          var cityCircle = new google.maps.Circle({
            strokeColor: '#d26ce7',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#d26ce7',
            fillOpacity: 0.35,
            map: map,
            center: citymap[city].center,
            radius: Math.sqrt(citymap[city].size) * 100
        });
      }

//        // Add circle overlay and bind to marker
// var circle = new google.maps.Circle({
//   map: map,
//   radius: 16093,    // 10 miles in metres
//   fillColor: '#AA0000'
// });
// circle.bindTo('center', marker, 'position');

    // Override our map zoom level once our fitBounds function runs (Make sure it only runs once)
    var boundsListener = google.maps.event.addListener((map), 'bounds_changed', function(event) {
        this.setZoom(8);
        google.maps.event.removeListener(boundsListener); 
    });
}

}

(function($) {

    // Fade map text on click
    $(document).ready(function() {
        var $mapHeading = $('.contact-with-map__map-wrapper__overlay');
        $mapHeading.on('click',function() {
            $mapHeading.fadeOut('slow');
        });
    });

})(jQuery);
